.header {
  border-bottom: 5px solid #934253;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(180, 180, 180, 0.486);

  .logo-image {
    height: 100px;
    width: auto;
    padding-top: 1rem;
  }

  .logo {
    padding-bottom: 2rem;
  }
}
