.medical-form {
  padding-bottom: 2rem;
  font-size: 14px;

  .medical-information__wrapper {
    box-shadow: 0 0 2px 1px rgba(180, 180, 180, 0.486);
  }

  .form-title {
    margin-top: 2rem;
    padding-bottom: 1rem;
    background-color: #c3576f;
    color: white;
    font-weight: bold;
    padding: 1rem 15px;
    width: 100%;
    margin: 0;
    font-size: 20px;
  }

  .medical-information__wrapper {
    background-color: white;
    padding-bottom: 1rem;
  }

  .input-field__wrapper {
    margin-top: 1rem;
  }

  .analysis-unit {
    margin-top: 2rem;
    background-color: white;
    box-shadow: 0px 0px 2px 1px rgba(180, 180, 180, 0.486);
  }

  input,
  textarea {
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
  }

  textarea {
    max-height: 100px;
  }

  .analysis-unit__form-title {
    background-color: #c3576f;
    color: white;
    font-weight: bold;
    padding: 1rem 15px;
    width: 100%;
    margin: 0;
    font-size: 22px;
  }

  .analysis-unit__sub-block-title {
    background-color: rgb(199, 199, 199);
    width: 100%;
    padding: 0.5rem 15px;
    margin: 0;
    font-size: 18px;
  }

  .analysis-unit__sub-title {
    background-color: #eeeeee;
    width: 100%;
    padding: 0.2rem 15px;
    margin: 0;
    color: rgb(53, 53, 53);
    font-size: 18px;
  }

  .analysis-unit__description {
    background-color: rgba(126, 185, 45, 0.24);
    padding: 1rem 15px;
  }

  .input-title {
    font-weight: 600;
    color: rgb(26, 26, 26);
  }

  .input__field {
    width: 100%;
  }

  .check-box {
    margin-bottom: 0;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-radius: 3px;

    &:hover {
      background-color: #eefddb;
    }
  }

  .active {
    background-color: #eefddb;
    box-shadow: 0 0 1px 1px #c1cdd4;
  }

  .check-box__field {
    margin-right: 15px;
    cursor: pointer;
    margin-top: 0.3rem;
  }

  .input-description {
    color: grey;
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .input-label {
    width: 100%;
  }

  .beschikbare_inzendmaterialen {
    min-width: 50px;
    font-weight: bold;
    color: #c3576f;
    display: flex;
    justify-content: center;

    span {
      &::after {
        content: "/";
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  .input-description__item {
    &::after {
      content: "";
      border-left: 1px solid lightgrey;
      margin: 0 10px;
    }

    &:last-child {
      &::after {
        content: none;
        border-left: none;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .submit-button {
    margin: 1rem 0;
    padding: 5px 30px;
    margin-bottom: 5rem;
  }

  .successfully-submitted {
    background-color: rgb(227, 248, 220);
    width: 100%;
    padding: 10px 15px;
    border-radius: 3px;
    border-color: rgb(104, 187, 93);
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    margin: 1rem 0;
  }

  .selected-fields {
    margin-top: 2rem;
  }

  .selected-fields__block {
    background-color: white;
    box-shadow: 0px 0px 2px 1px rgba(180, 180, 180, 0.486);
    padding-bottom: 1rem;
  }

  .selected {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgb(243, 237, 237);
    }
  }

  .selected-fields__sub-title {
    font-size: 20px;
    margin-top: 1rem;
  }

  .selected-fields__no-select {
    background-color: white;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    color: grey;
  }

  .selected-fields__beschikbare_inzendmaterialen--wrapper {
    display: flex;
  }

  .selected-fields__beschikbare_inzendmaterialen {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-bottom: 2px dotted #c3576f;
    color: #c3576f;
    cursor: default;
  }

  .tooltip {
    font-weight: bold;

    .tooltip__content {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        display: block;
        height: 15px;
        width: 15px;
        border-radius: 50px;
        margin-right: 15px;
      }
    }

    .feces {
      &::before {
        background-color: #7f3221;
      }
    }

    .urine {
      &::before {
        background-color: #ffeb3d;
      }
    }
  }

  .submit-button.btn {
    background-color: #7eb92d;
    border-color: #7eb92d;
    color: #fff;

    &:hover,
    &:active {
      background-color: #5a891a;
      border-color: #5a891a;
    }

    &:disabled {
      background-color: #7eb92d;
      border-color: #7eb92d;
    }
  }
}
